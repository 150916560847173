import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './BarPage.css';
import { BASE_URL } from "./constants";
import LanguageSelector from './LanguageSelector';
import MaintenanceMode from "./MaintenanceMode";
import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

const BarPage = () => {
    const { t, i18n } = useTranslation();
    const [selectedPrice, setSelectedPrice] = useState('');
    const [selectedHours, setSelectedHours] = useState('');
    const [selectedOptionId, setSelectedOptionId] = useState('');
    const [barData, setBarData] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state for initial data fetch
    const [checkoutLoading, setCheckoutLoading] = useState(false); // Add loading state for checkout request
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setLoading(true); // Start loading
            axios.get(`${BASE_URL}/bar/${id}`)
                .then(response => {
                    setBarData(response.data);
                    setLoading(false); // End loading
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false); // End loading
                });
        }
    }, [id]);

    const calculateAmountInCents = () => {
        return selectedPrice ? parseFloat(selectedPrice) * 100 : 0;
    };

    const handleCheckout = async () => {
        if (!window.Stripe) {
            console.error('Stripe.js has not loaded.');
            UIkit.notification({
                message: 'Payment cannot be processed at this time. Please try again later.',
                status: 'danger',
                pos: 'top-center',
                timeout: 5000
            });
            return;
        }

        setCheckoutLoading(true); // Start checkout loading
        try {
            const { data } = await axios.post(`${BASE_URL}/create-checkout-session`, {
                    amount: calculateAmountInCents(),
                    hours: selectedHours,
                    barId: id,
                    locale: i18n.language
                },
                {
                    headers: {
                        'Accept-Language': i18n.language
                    }
                });
            const stripe = window.Stripe('pk_live_51PH8bgFpgcnFO1sZseck0OpPI4QDKmKNvaXV7NsVSIQDk2vvAhw0JPhEYnI0o4JIwxyvCvFIcVoeUJOm0hK2JcMQ00c0Zuy9J5');
	    // pk_test_kFAVrRETd3hN6GBb7ZfAWv6R00LvslCxjU
            stripe.redirectToCheckout({ sessionId: data.id });
        } catch (error) {
            console.error('Error:', error);
            UIkit.notification({
                message: 'Error processing payment',
                status: 'danger',
                pos: 'top-center',
                timeout: 5000
            });
        } finally {
            setCheckoutLoading(false); // End checkout loading
        }
    };

    const handleOptionSelection = (e) => {
        const { value } = e.target;
        setSelectedOptionId(value); // Update the selected option ID
        const selectedOption = barData.plan.find(item => item._id === value);
        if (selectedOption) {
            setSelectedPrice(selectedOption.price); // Update the price based on the selected option
            setSelectedHours(selectedOption.hour);  // Update the hours based on the selected option
        }
    };

    const navigateToGamesList = () => {
        navigate(`/games/${id}`);
    };

    const navigateToSupportContact = () => {
        navigate(`/support/${id}`);
    };

    // Early return if in maintenance mode
    if (barData && barData.maintenanceMode) {
        return (
            <div>
                <MaintenanceMode/>
            </div>
        );
    }

    return (
        <div className="uk-container uk-margin-large-top">
            {loading ? (
                <div className="uk-flex uk-flex-center uk-flex-middle" uk-height-viewport="expand: true">
                    <div uk-spinner="ratio: 3"></div>
                </div>
            ) : (
                <>
                    <header>
                        <h1 className="uk-text-center">{barData ? barData.name : t('loading')}</h1>
                        <div className="uk-flex uk-flex-center">
                            <LanguageSelector/>
                        </div>
                    </header>
                    <p className="uk-text-center">{t('workingHours', {
                        from: barData ? barData.schedule[0].from : "",
                        till: barData ? barData.schedule[0].till : ""
                    })}</p>

                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="hours-select">{t('selectPlayTime')}</label>
                        <div className="uk-form-controls">
                            <select className="uk-select" id="hours-select" value={selectedOptionId}
                                    onChange={handleOptionSelection}>
                                <option value="">{t('selectPlayTime')}</option>
                                {barData && barData.plan.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {item.hour < 1 ? item.hour * 60 + ' ' + t('minutes') : item.hour + ' ' + (item.hour > 1 ? t('hours') : t('hour'))}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="uk-margin uk-text-center">
			<>
                            <label className="uk-form-label">{t('amountToPay')}</label>
                            <div className="uk-form-controls">
                                <span>{selectedPrice}zł</span>
                            </div>
                        </>
                    </div>

                    <div className="uk-margin uk-flex uk-flex-column uk-flex-center vertical-buttons-container">
                        <button className="uk-button uk-button-primary action-button" onClick={handleCheckout}
                                disabled={!selectedHours || checkoutLoading}>
                            {checkoutLoading ? <div uk-spinner="ratio: 0.5"></div> : t('proceedWithPayment')}
                        </button>
                        <button className="uk-button uk-button-secondary action-button"
                                onClick={navigateToGamesList}>{t('showGamesList')}</button>
                        <button className="uk-button uk-button-secondary action-button"
                                onClick={navigateToSupportContact}>{t('contactSupport')}</button>
                    </div>
                </>
            )}
        </div>
    );
};

export default BarPage;
