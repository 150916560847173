import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.css'; // Import your CSS file for styling

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <select className="uk-select language-selector" onChange={changeLanguage} defaultValue={i18n.language}>
            <option value="pl">Polski</option>
            <option value="en">English</option>
            <option value="de">Deutsch</option>
            <option value="fr">Français</option>
            {/*<option value="es">Español</option>*/}
        </select>
    );
};

export default LanguageSelector;
