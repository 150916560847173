import React from 'react';
import { useTranslation } from 'react-i18next';
import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import './MaintenanceMode.css'; // Import your CSS file

UIkit.use(Icons);

const MaintenanceMode = () => {
    const { t } = useTranslation();

    return (
        <div className="uk-section uk-section-muted uk-flex uk-flex-middle uk-flex-center maintenance-mode">
            <div className="uk-container uk-text-center">
                <span data-uk-icon="icon: wrench; ratio: 2" className="uk-icon uk-margin-bottom"></span>
                <h2 className="uk-heading-medium">{t('maintenanceMode')}</h2>
                <p className="uk-text-large">{t('maintenanceMessage')}</p>
            </div>
        </div>
    );
};

export default MaintenanceMode;
