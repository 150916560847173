import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from './constants';
import axios from 'axios';
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit-icons';
import { useTranslation } from 'react-i18next';

const SuccessPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const barId = queryParams.get('bar_id');
    const [paymentData, setPaymentData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Make a request to fetch payment data using the transactionId
        axios.get(`${BASE_URL}/transaction/${sessionId}`)
            .then(response => {
                setPaymentData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching payment data:', error);
                setLoading(false);
            });
    }, [sessionId]);

    const handleReturnHome = () => {
        navigate(`/bar/${barId}`);
    };

    if (loading) {
        return <div className="uk-text-center uk-margin-large-top">Loading...</div>;
    }

    const paymentDetailsText = paymentData.hours < 1
        ? t('paymentSuccessfulDetailsMinutes', { minutes: paymentData.hours * 60 })
        : t('paymentSuccessfulDetails', { hours: paymentData.hours });

    return (
        <div className="uk-container uk-container-small uk-margin-large-top">
            <h2 className="uk-heading-line uk-text-center">
                <span>{t('paymentSuccessful')}</span>
            </h2>
            <div className="uk-flex uk-flex-center uk-margin">
                <span uk-icon="icon: check; ratio: 3" className="uk-text-success"></span>
            </div>
            <div className="uk-card uk-card-default uk-card-body">
                <p className="uk-text-lead">{paymentDetailsText}</p>
                <p className="uk-text-meta">
                    {t('receiptSent', { email: paymentData.session.customer_details.email })}
                    <a className="uk-link-text" href={paymentData.session.receipt_url}>{t('here')}</a>.
                </p>
                <button className="uk-button uk-button-primary uk-margin-top" onClick={handleReturnHome}>
                    {t('returnHome')}
                </button>
            </div>
        </div>
    );
};

export default SuccessPage;
