import React, { useEffect, useState } from 'react';
import './GamesList.css'; // Import the CSS file for styling
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "./constants";
import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

UIkit.use(Icons);

const GamesList = () => {
    const { t } = useTranslation();
    const { barId } = useParams();
    const navigate = useNavigate();
    const [gamesList, setGamesList] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (barId) {
            axios.get(`${BASE_URL}/bar/${barId}/games`)
                .then(response => {
                    setGamesList(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                });
        }
    }, [barId]);

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };

    return (
        <div className="games-list-container uk-container">
            <div className="title-container uk-flex uk-flex-middle">
                <button className="uk-button uk-button-default back-button" onClick={handleBackClick}>
                    <span uk-icon="icon: arrow-left; ratio: 1.5"></span>
                </button>
                <h2 className="games-list-title uk-heading-line"><span>{t('gamesList')}</span></h2>
            </div>
            {loading ? (
                <div className="uk-flex uk-flex-center uk-flex-middle" uk-height-viewport="expand: true">
                    <div uk-spinner="ratio: 3"></div>
                </div>
            ) : (
                <div className="games-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m" uk-grid="true">
                    {gamesList && gamesList.map((game, index) => (
                        <div key={index} className="game-card uk-card uk-card-default uk-card-hover uk-card-body">
                            <img src={`data:image/png;base64,${game.coverImageBase64}`} alt={game.name} className="game-image" />
                            <div className="game-details">
                                <span className="game-name">{game.name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GamesList;
