import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import './SupportContact.css'; // Import the CSS file for custom styling
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { BASE_URL } from "./constants";

// loads the Icon plugin
UIkit.use(Icons);

const SupportContact = () => {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [issueDescription, setIssueDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const textareaRef = useRef(null);  // Reference to access the textarea DOM element
    const { barId } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await axios.post(`${BASE_URL}/report-issue`, {
                name: name,
                phoneNumber: phoneNumber,
                issueDetail: issueDescription,
                barId: barId,
                locale: i18n.language
            });  // Send data to your backend endpoint

            setName('');
            setPhoneNumber('');
            setIssueDescription('');
            setIsSubmitting(false);

            UIkit.notification({ message: 'Your message has been sent successfully!', status: 'success' });
        } catch (error) {
            console.error('Error sending message:', error);
            setIsSubmitting(false);
            UIkit.notification({ message: 'Failed to send message. Please try again later.', status: 'danger' });
        }
    };

    const adjustTextareaHeight = (element) => {
        element.style.height = 'auto';  // Temporarily shrink to fit new content
        element.style.height = `${element.scrollHeight}px`;  // Set to exact scroll height
    };

    const handleTextareaChange = (e) => {
        setIssueDescription(e.target.value);
        adjustTextareaHeight(e.target);
    };

    // Adjust the height on initial component mount (for pre-filled values)
    useEffect(() => {
        if (textareaRef.current) {
            adjustTextareaHeight(textareaRef.current);
        }
        if (barId) {
            console.log("BAR ID:", barId);
        }
    }, [barId]);

    return (
        <div className="uk-container uk-margin-large-top support-contact-container">
            <h2 className="uk-heading-line uk-text-center"><span>{t('contactSupport')}</span></h2>
            <form className="uk-form-stacked contact-form" onSubmit={handleSubmit}>
                <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="name">{t('name')}:</label>
                    <div className="uk-form-controls">
                        <input className="uk-input" type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="phoneNumber">{t('phoneNumber')}:</label>
                    <div className="uk-form-controls">
                        <input className="uk-input" type="tel" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                    </div>
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label" htmlFor="issueDescription">{t('issueDescription')}:</label>
                    <div className="uk-form-controls">
                        <textarea
                            className="uk-textarea"
                            ref={textareaRef}
                            id="issueDescription"
                            value={issueDescription}
                            onChange={handleTextareaChange}
                            required
                        />
                    </div>
                </div>
                <button type="submit" className="uk-button uk-button-primary uk-width-1-1" disabled={isSubmitting}>
                    {isSubmitting ? t('sending') : t('send')}
                </button>
            </form>
        </div>
    );
};

export default SupportContact;
