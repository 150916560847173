import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BarPage from './BarPage';
import GamesList from "./GamesList";
import SupportContact from "./SupportContact";
import SuccessPage from './SuccessPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/bar/:id" element={<BarPage />} />
                <Route path="/games/:barId" element={<GamesList />} />
                <Route path="/support/:barId" element={<SupportContact />} />
                <Route path="/success" element={<SuccessPage />} />
            </Routes>

        </Router>
    );
}

export default App;
